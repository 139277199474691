const isWhitelistedKey = (key) => (
  /^gclid$/i.test(key) ||
  /^gclsrc$/i.test(key) ||
  /^dclid$/i.test(key) ||
  /^agentCode$/i.test(key) ||
  /^nui$/i.test(key) ||
  /^m_i$/i.test(key) ||
  /^fbclid$/i.test(key) ||
  /^gbraid$/i.test(key) ||
  /^wbraid$/i.test(key) ||
  /^fbc$/i.test(key) ||
  /^fbp$/i.test(key) ||
  /^fbtrace_id$/i.test(key) ||
  /^scclid$/i.test(key) ||
  /^pin_id$/i.test(key) ||
  /^ttclid$/i.test(key) ||
  /^li_fat_id$/i.test(key) ||
  /^Igclid$/i.test(key) ||
  /^smc_upi$/i.test(key) ||
  /^rtbh$/i.test(key) ||
  /^msclkid$/i.test(key) ||
  /^ranCID$/i.test(key) ||
  /^ranMID$/i.test(key) ||
  /^ranSubID$/i.test(key) ||
  /^TDUID$/i.test(key) ||
  /^Fid$/i.test(key) ||
  /^cid$/i.test(key) ||
  /^pid$/i.test(key) ||
  /^tid$/i.test(key) ||
  /^Tam$/i.test(key) ||
  /^utm_/i.test(key) ||
  /^ptm_/i.test(key)
)

export const GET_WHITELISTED_PARAMS = (params) => {
  return params
    ? Object.keys(params)
      .filter(key => isWhitelistedKey(key))
      .reduce((obj, key) => ({
        ...obj,
        [key]: params[key]
      }), {})
    : {}
}

export const EXCLUDE_WHITELISTED_PARAMS = (params) => {
  return params
    ? Object.keys(params)
      .filter(key => !isWhitelistedKey(key))
      .reduce((obj, key) => ({
        ...obj,
        [key]: params[key]
      }), {})
    : {}
}
