import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-package-block__tokens.json')['c-package-block']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/package-block/data/c-package-block__tokens.json`)['c-package-block'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * The PackageBlockData contains all data needed to hydrate a PackageBlock view
 *
 * @typedef {Object}          PackageBlockData
 *
 * @property {String[]}       includedServices      - The included services on the package (acco, flight, ...)
 * @property {String}         departureDate         - The package departure date, expressed as human readable string
 * @property {String}         rawDepartureDate      - The package departure date, expressed as machine readable string
 * @property {String[]}       filters               - The main filters applied to get a package offer
 * @property {String}         [extraClasses]        - Extra classes to be appended
 */
export const defaultPackageBlockData = {
  includedServices: [],
  departureDate: '',
  rawDepartureDate: '',
  filters: []
}

export function PackageBlockTemplate (model) {
  return `
      <div class="c-package-block ${model.extraClasses ? model.extraClasses : ''}">
          ${model.includedServices && model.includedServices.length
            ? `<ul class="c-package-block__included o-list-bare">
              ${model.includedServices.map(includedService => `<li class="c-package-block__included-item"><span class="m-icon ${getTokenClass('icon', includedService, tokens)}"></span></li>`).join('').trim()}
            </ul>`
            : ''}
          ${model.departureDate
          ? `<div class="c-package-block__departure m-body qa-departure-date" data-raw-date="${model.rawDepartureDate}">
          ${model.departureDate}
          ${model.arrivalDate ? `<span class="c-package-block__arrival-date"> - ${model.arrivalDate}</span>` : ''}
          </div>`
: ''}
          ${model.filters && model.filters.length
            ? `<ul class="c-package-block__filters o-list-bare m-body m-body--tiny">
              ${model.filters.map(filter => `<li class="c-package-block__filters-item">${filter}</li>`).join('').trim()}
            </ul>`
            : ''}
          ${model.outboundDepartureAirportName
            ? `<div class="c-package-block__outbound-departure-airport-name-container">
                <span class="m-icon m-icon--airplane-departure"></span>
                <span>${model.outboundDepartureAirportName}</span>
               </div>`
            : ''}
      </div>`
}
